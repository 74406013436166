import React, { useState, useEffect } from 'react';

const App: React.FC = () => {
  const [AppComponent, setAppComponent] = useState<React.FC | null>(null);
  useEffect(() => {
    const loadAppComponent = async () => {
      if (process.env.VITE_APP_NODE_ENV === 'dev') {
        const { default: AppLocal } = await import('./AppLocal');
        setAppComponent(() => AppLocal);
      } else {
        const { default: AppProduction } = await import('./AppProd');
        setAppComponent(() => AppProduction);
      }
      
    };

    loadAppComponent();
  }, []);

  return AppComponent ? <AppComponent /> : null;
};

export default App;
